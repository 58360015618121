const GetLoggedUserFromStorage = () => {
  if (typeof window === 'undefined') {
    // on server
    return null
  }
  // on browser
  const stringifiedProfileJson = localStorage.getItem('profile')
  try {
    return JSON.parse(stringifiedProfileJson)
  } catch {
    return {}
  }
}

export default GetLoggedUserFromStorage
