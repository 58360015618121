import React, { useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import 'tailwindcss/tailwind.css'
import '../styles/globals.css'
import GetLoggedUserFromStorage from '../utils/GetLoggedUserFromStorage'
import UserContext from '../contexts/UserContext'
import { Helmet } from 'react-helmet'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [loggedUser, setLoggedUser] = useState(GetLoggedUserFromStorage())
  const loginUser = (user) => {
    localStorage.setItem('profile', JSON.stringify(user))
    setLoggedUser(user)
  }

  const logoutUser = () => {
    localStorage.clear()
    setLoggedUser(null)
    router.push('/')
  }

  return (
    <UserContext.Provider
      value={{
        loggedUser,
        loginUser,
        logoutUser,
      }}
    >
      <Helmet>
        <html prefix="og:http://ogp.me/ns#" lang="en" />
      </Helmet>
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="1 days" />
        <meta name="author" content="Kahan Chale" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="google-site-verification" content="jPjylnF6VFYjy9JPLO94lXsUjqYOQRfrgVTkvCtLPfg" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "gb59ue1x7o");
`,
          }}
        />
      </Head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-N1KSEYB94E" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', 'G-N1KSEYB94E');`,
        }}
      />
      <div className="">
        <Component {...pageProps} />
      </div>
    </UserContext.Provider>
  )
}

export default MyApp
